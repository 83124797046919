

/* ============================================ *
 * Load
 * ============================================ */
@import "var/variables.scss";

@import 'tools/normalize.scss'; // normalize
@import 'tools/print.scss'; // print
@import 'tools/clearfix.scss'; 


@import "tools/grid/grid.scss"; // bootstrap grid

// fonts


@import "var/fonts.scss"; // Fonts and Headings
@import "var/icons.scss"; // icons




@import "tools/mixins/_visuallyhidden.scss";
@import 'tools/reset.scss'; // reset
@import 'tools/modals.scss';
@import 'tools/buttons.scss';
@import 'tools/forms.scss';
@import 'tools/tables.scss';
@import 'tools/tabs.scss';






/* ============================================ *
 * Layout
 * ============================================ */
@import 'layout/header.scss';
@import 'layout/navigation.scss';
@import "layout/breadcrumbs.scss";
@import "layout/toolbar.scss";
@import 'layout/messages.scss';
@import 'layout/page.scss';
@import 'layout/home.scss';

@import 'layout/footer.scss';

/* ============================================ *
 * Modules
 * ============================================ */
@import 'modules/page-builder.scss';
@import 'modules/cms.scss';
@import 'modules/theme.scss';

/* Catalog */
@import 'modules/catalog/catalog.scss';
@import 'modules/catalog/price.scss';
@import 'modules/catalog/swatches.scss';
@import 'modules/catalog/catalog-layerdnav.scss';
@import 'modules/catalog/catalog-list.scss';
@import 'modules/catalog/catalog-product.scss';



/* customer */
@import 'modules/customers/customers.scss';


/* Chekcout */
@import 'modules/checkout/cart.scss';
@import 'modules/checkout/minicart.scss';
@import 'modules/checkout/checkout.scss';
@import 'modules/checkout/multishipping.scss';



@import 'modules/review.scss';
@import 'modules/wishlist.scss';
@import 'modules/newsletter.scss';
@import 'modules/contact.scss';


/* Extra *
//@import 'modules/manadev.scss';

/* ============================================ *
 * Lib Files
 * ============================================ */      

@import 'lib/slick/slick.scss';
@import 'lib/slick/slick-theme.scss';