/* ============================================ *
 * Font Icons
 * ============================================ */
// How to use
// @include font-icon('\f1e2');
// @include font-icon-before('\f1e2');
// @include font-icon-after('\f1e2');

@mixin font-icon($content) {
	font-family: 'bolt-icons';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	content: '#{$content}';
}
@mixin font-icon-before($content) { 
	&:before { 
		font-family: 'bolt-icons';
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		content: '#{$content}'
	}
}
@mixin font-icon-after($content) { 
	&:after { 
		font-family: 'bolt-icons';
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		content: '#{$content}'
	}
}