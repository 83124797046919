/* ============================================ *
 * Default
 * ============================================ */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[name=email],
input[type="text"],
input[type="number"],
input.input-text,
select,
textarea { 

  display: block;
  width: 100%;
  max-width: 100%; 

  background:#fff; 
  border: $border-default;
  
  color: $font_colour-default;

  &:focus {
    outline: 0;
    outline: none;
    border-color: #000;
  }

}

textarea { 
  padding: 15px;
  overflow:auto;
  width: 100%;
  min-width: 280px;
  min-height: 120px;
}

input.radio { margin-right:3px; }
input.checkbox { }
// qty box srart
input.qty,
input.item-qty {
  width: 42px;
  max-width: 42px;
  text-align: center;
  padding:0 5px;
}

.quantity-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .minus,
  .plus {
    width: 30px;
    height: 30px;
    display: block;
    background: $colour_secondary;
    color: #fff;
    position: relative;
    font-size: 0;
    &:before {
      @include font-icon('\e918');
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      display: block;
      width: 16px;
      height: 16px;
    }
  }
  .plus {
    &:before {
      @include font-icon('\e91a');
      
    }
  }
  span {
    padding: 0;
  }
}
// qty box end




input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}


input {
  margin: 0;
	line-height: 40px;
	height: 42px; // 2px for the borders
	padding: 0 15px;
	outline: 0;
	outline: none;
	padding-right: 40px; // space for validation icon if needed
}

input[type="file"] {
  padding: 0;
}
input[type="checkbox"] {
  height: inherit;
  display: inline-block;
  margin: 2px 5px 0 0;
  position: relative;
  top: 2px;
  width: auto;
}


select {
    display: block;
    margin: 0;
    padding: 5px 15px 5px 5px;

    border: $border-default;

    font-size: 12px !important;
    font-family: sans-serif;

    color: #444;
    line-height: 1.3;
    outline: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      ;//linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 5px top 50%,0 0;
    background-size: 8px auto,100%;
    height: 42px;
    width: 100%;
}
select::-ms-expand {
    display: none;
}
select:hover {
    border-color: #888;
}
select:focus {
    border-color: #000;
    color: #000; 
    outline: none;
}
select::-moz-focus-inner { 
  border: 0; 
}
select option {
    font-weight:normal;
}



select.multiselect option { 
  border-bottom: $border-default; 
  padding: 2px 5px;
}
select.multiselect option:last-child { border-bottom: 0; }

fieldset,
.fieldset {
	border: 0;
  margin: 0;
  padding: 0; 
	
	.field {
	
    border: 0;
    padding: 0;
    margin-bottom: 15px;
		
		label {
			width: 100%;
			display: block;
			margin-bottom: 5px;
		}
		input { 
      
    }
    input[type="checkbox"] {

    }
    &.note {
			display: block;
			margin-bottom: 30px;
		}
		&.newsletter {
		  line-height: 1;
    	input {

    	}
    }
    &.choice {
      label {
        margin: 0;
        width: auto;
        display: inline-block;
      }
    }
	}
  .legend {
    margin: 0 0 15px;
    padding: 0 0 15px;
    width: 100%;
    
    display: block !important; // don't know why 

    border-bottom: $border-default;

    font-size: $font_size-lg;
    font-weight: bold;
  }
}





// REVIW FROM HERE - move to fieldset tag
.fieldset {
    span.note {
      display: block;
      padding-top: 5px;
      font-size: $font_size-sm;
    }
		._with-tooltip {
			position: relative;
			padding-right: 30px;
      @include media-breakpoint-down(sm) {
        padding-right: 0;
      }
		}

}

.field-tooltip {
	cursor: pointer;
	position: absolute;
	right: 0px;
  top: 10px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
  
	&:before {
    @include font-icon('\e910');
		width: 20px;
		height: 20px;
		font-size: 20px;
    color: $colour_secondary;
	}
  span {
    display:none;
  }
	.field-tooltip-content {
		background: #fff;
		border: $border-default;
		border-radius: 1px;
		font-size: 14px;
		padding: 15px;
		width: 270px;
		display: none;
		left: 25px;
		position: absolute;
		text-transform: none;
		top: -16px;
		word-wrap: break-word;
		z-index: 2;
    font-size: $font_size-sm;
		
		
	}
	&._active {
		.field-tooltip-content {
			display: block;
		}
  }
	&:hover {
		.field-tooltip-content {
			display: block;
		}
	}
}


.field.required >.label:after{
    content: '*';
    margin: 0 0 0 5px;
}


/* ============================================ *
 * Password Strength Meter
 * ============================================ */
.password-strength-meter {
  background-color: #f4f4f4;
  height: 32px;
  line-height: 32px;
  padding: 0 9px;
  position: relative;
  z-index: 1;
}
.password-strength-meter:before {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}
.password-none .password-strength-meter:before {
  background-color: #f4f4f4;
  width: 100%;
}
.password-weak .password-strength-meter:before {
  background-color: #ffafae;
  width: 25%;
}
.password-medium .password-strength-meter:before {
  background-color: #ffd6b3;
  width: 50%;
}
.password-strong .password-strength-meter:before {
  background-color: #c5eeac;
  width: 75%;
}
.password-very-strong .password-strength-meter:before {
  background-color: #81b562;
  width: 100%;
}
