
/* ============================================ *
 * Multishipping Page
 * ============================================ */
.multishipping-checkout-addresses {
	.page-title-wrapper {
		margin-top: 60px;
	}
}

form.multicheckout {
	text-align: left;

	.title {
		padding-bottom: 30px;
	}
	.product.name {
		display: block;
		margin-bottom: 15px;

	}
	.item-options {
		display: block;
		dt {
			display: block;
			float: left;
			padding-right: 5px;
			clear: left;
			font-weight: bold;
			&:after {
				content: " :";
			}
		}
		dd {
			display: block;
			float: left;
			
		}
	}

	.action.delete {
		display: block;
		margin: 20px 0 0;

	}

	.actions-toolbar {
		margin: 30px 0;
		display: flex;
		justify-content: space-between;

		.secondary {
			display: flex;
			@include media-breakpoint-up(sm) {
				justify-content: space-between;
			}
			button {
				margin-right: 15px;
			}
		}
	}

// mobile 
	@include media-breakpoint-down(sm) {
		thead {
			display: none;
		}
		tr {
			display: block;
		}
		.col.product {
			display: block;
			width: 100%;
			float: left;
		}
		.action.delete span {
			display: none;
		}
	
		.actions-toolbar {
			margin: 30px 0;
			display: block;

			.secondary {
				margin-top: 15px;
				display: block;
				button {
					margin-right: 0;
					margin-bottom: 15px;
				}
			}
		}
	}

}