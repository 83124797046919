/* ============================================ *
 * Layerd Nav
 * ============================================ */
.block.filter {

    //text-align: left;




    .filter-title {

        //@include heading-3 margin-bottom: 15px;

        @include media-breakpoint-down(sm) {
            //cursor: pointer;
        }

        strong {
            //font-weight: normal;

            @include media-breakpoint-down(sm) {
                //background: #f00;
                //padding: 10px 30px;
                //display: inline-block;

            }

        }
    }

    .filter-content {
        //display: none;

        @include media-breakpoint-up(sm) {
            //padding-right: 30px;
            //display: block;
        }
    }

    .block-subtitle {
        //@include visuallyhidden;
    }

    // @include media-breakpoint-down(sm) {
    //   display: none;
    // }


    // mobile

}

// mobile 
.mobile-nav-header {
    display: none;
}

@include media-breakpoint-down(sm) {
    .mobile-nav-header {
        display: flex;
        justify-content: space-between;
        background: #d00;

        padding: 15px;

        .back {}
    }


    .filter-options-item {
        //background: #fff;

        .filter-options-title {
            //z-index: 999;
        }

        .filter-options-content {
            /*

            transition: all 0.3s ease;
            display: block !important;
            position: fixed;
            top: 0;
            left: 100%;
            width: 100%;
            height: 100%;
            background: #fff;
            margin: 0;
            //padding: 30px;
            z-index: 9999;*/
        }
    }

    // parent
    .filter {
        //transition: all 0.3s ease;
    }

    .filter.sub-open {
        //left: -10% !important;

    }



}

// end mobile








// My changes
// this should be in page.scss but i add this here to review
.page-layout-2columns-left .sidebar {
    z-index: 111;
}

@include media-breakpoint-up(md) {
    .sidebar {
        .filter {
            text-align: left;
        }

        .filter-options {
            margin-top: 15px;
        }

        .filter-options-title {
            cursor: pointer;
            display: block;
            border-bottom: $border-default;
            position: relative;
            padding-bottom: 15px;
            margin-bottom: 15px;

            &:after {
                position: absolute;
                right: 0;
                top: 3px;
                @include font-icon('\e91a');
            }

            &.active {
                &:after {
                    @include font-icon('\e918');
                }
            }

        }

        .filter-options-content {
            overflow: hidden;
            transition: all 0.3s ease-out;
            position: relative;

            &:not(.active) {
                height: 0 !important;
            }

            &.active {
                margin-top: 15px;
                margin-bottom: 30px;
            }

            .items {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }

            .item {
                a {
                    display: block;
                    margin-bottom: 10px;
                }

                .count {
                    //font-size: $font_size-sm;
                    color: $colour_secondary;

                    &:before {
                        content: "(";
                    }

                    &:after {
                        content: ")";
                    }
                }

                .filter-count-label {
                    @include visuallyhidden;
                }
            }

            .swatch-attribute-options,
            .swatch-attribute {
                margin: 0;
            }
        }
    }

    .main {
        .mana-filter-block-above-horizontal {
            strong {
                @include visuallyhidden;
            }
        }

        .filter-options {
            text-align: left;
        }

        .filter-options-item {
            display: inline-block;
            position: relative;
            z-index: 111;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }

        .filter-options-title {
            border: 1px solid $secondary-colour;
            min-width: 180px;
            height: 50px;
            line-height: 50px;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
            border-radius: 25px;
            cursor: pointer;
            position: relative;
            z-index: 111;

            &:after {
                @include font-icon('\e907');
                display: inline-block;
                margin-left: 4px;
                vertical-align: middle;

            }
        }

        .filter-options-content {
            min-width: 276px;
            border-radius: 20px;
            position: absolute;
            top: calc(100% + 16px);
            left: calc(50% - 138px);
            display: none;

            &.active {
                display: inline;
            }

            .item {
                height: 53px;
                line-height: 53px;
                padding: 0 18px;
                background-color: #fff;

                &:first-child {
                    border-radius: 25px 25px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 25px 25px;
                }

                a {
                    padding-left: 23px;
                    position: relative;
                    line-height: 16px;
                    display: inline-block;

                    &:before {
                        position: absolute;
                        content: "";
                        height: 16px;
                        width: 16px;
                        border: 1px solid #000;
                        left: 0;
                        top: 0;
                        border-radius: 2px;
                        background-color: #fff;

                    }

                    &:after {
                        position: absolute;
                        content: "\2713";
                        font-size: 13px;
                        left: 3px;
                        top: 0px;
                        color: #fff;
                        opacity: 0;
                        z-index: 1;

                    }
                    &:hover {

                        &:before {
                            background-color: $primary-colour;
                        }

                        &:after {
                            opacity: 1;
                        }
                    }
                }

            }
        }

        .swatch-attribute.color {
            display: block;
            margin: 0;
            background-color: #fff;
            border-radius: 20px;
            padding: 15px 23px;

            .swatch-attribute-options {
                margin: 0;
            }

            a {
                display: inline-block;
                margin: 0 10px 0 0;
            }

            .color {
                width: 28px;
                height: 28px;
                margin: 0;
            }
        }
        .filter-current {
            .items {
                text-align: left;
            }
            .item {
                display: inline-block;
                padding: 2px 20px 0 4px;
                background-color: rgba(0,0,0,0.11);
                border: 1px solid rgba(0,0,0,0.11);
                position: relative;
                margin-right: 5px;
                
            }
            .remove {
                span {
                    display: none;
                }
                &:after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding-top: 4px;
                    height: 100%;
                    width: 16px;
                    @include font-icon('\e924');
                    display: inline-block;
                    margin-left: 5px;
                    background-color:  rgba(0,0,0,0.11);
                    text-align: center;
                    
                }
            }
        }
        .filter-actions {
            margin: $margin 0;
            text-align: left;
        }
    }

    .page-wrapper {
        &.filter_hover {
            position: relative;

            &:before {
                content: "";
                position: fixed;
                left: 0;
                top: 0;
                z-index: 11;
                height: 100%;
                width: 100%;
                background-color: rgba(#000, .13);
            }
        }
    }
}

@include media-breakpoint-down(sm) {

    // remove scroll on active filter
    body.filter-active .page-wrapper {
        position: fixed;
        overflow: hidden;
        height: 100%;
        left: 0;
        top: 0;
    }
    .main {
        .mana-filter-block {
            display: none;
        }
    }
    .filter, .mana-filter-block {
        text-align: left;
    }

    .filter-content {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        width: 100%;
        height: 100%;
        z-index: 1;
        visibility: hidden;
        transition: all 1s cubic-bezier(.25, .1, 0, 1.01);
        transform: translate(-100%, 0);
        display: flex;
        flex-flow: column;

        &.opened {
            transform: translate(0, 0);
        }

        &.active_bar {
            visibility: visible;
        }
    }

    .mobile-nav-header {}

    .filter-subtitle {
        display: block;
        position: relative;
        text-align: center;
        padding: 15px;
        border-bottom: $border-default-dark;

        &:after {
            content: var(--filter-title);
        }

    }

    #sidebar_toggle_close {
        position: absolute;
        left: 15px;
        top: 15px;
        cursor: pointer;
    }

    .icon-close {
        &:after {
            @include font-icon('\e924');
        }
    }

    .filter-title {
        display: inline-block;
        padding: 4px;
        border: $border-default-dark;
        border-radius: 3px;
        cursor: pointer;
        font-size: 12px;
        cursor: pointer;

        strong {
            background-color: transparent;
            line-height: 20px;
            color: $primary-colour;
            display: inline-block;
            font-size: 12px;

            &:hover {
                background-color: transparent;
                color: $primary-colour;
            }
        }

        &:before {
            color: $primary-colour;
            font-size: 22px;
            vertical-align: top;
            display: inline-block;
            line-height: 22px;
            margin-right: 6px;
            @include font-icon('\e913');
        }

        &.active {
            position: fixed;
            z-index: 11;
            top: 15px;
            left: 20px;
            border: none;
            font-size: 12px;
            line-height: 16px;
            padding: 0;

            &:before {
                @include font-icon('\e924');
                font-size: 18px;
            }

            &.back {
                &:before {
                    @include font-icon('\e908');
                }
            }
        }
    }

    .filter-current {
        order: 1;
        padding: 20px 15px;
        border-bottom: $border-default-dark;

        .item {
            border: $border-default-dark;
            border-radius: 3px;
            display: inline-block;
            padding: 4px 6px;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        .filter-label {
            @include visuallyhidden;
        }

        .remove {

            &:before {
                color: $primary-colour;
                font-size: 13px;
                vertical-align: top;
                display: inline-block;
                line-height: 22px;
                margin-left: 4px;
                @include font-icon('\e924');
                top: 2px;
                position: relative;
            }

            span {
                display: none;
            }
        }
    }

    .filter-current-subtitle {
        margin-bottom: 15px;
        display: block;

        &:before {
            color: $primary-colour;
            font-size: 16px;
            vertical-align: top;
            display: inline-block;
            line-height: 22px;
            margin-right: 6px;
            @include font-icon('\e913');
        }
    }

    .filter-actions {
        order: 3;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .filter-options {
        order: 4;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;

        .filter-options-title {
            padding: 20px 15px;
            transition: all 1s cubic-bezier(.25, .1, 0, 1.01);
            transform: translate(0, 0);
            cursor: pointer;
            display: block;
            border-bottom: $border-default;
            position: relative;

            &:after {
                @include font-icon('\e909');
                font-size: 18px;
                top: 21px;
                right: 17px;
                position: absolute;

            }
        }

        &.active {
            .filter-options-title {
                transform: translate(-100%, 0);
                opacity: 1;
                visibility: visible;
            }
        }

        .filter-options-content {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #fff;
            width: 100%;
            height: 100%;
            z-index: 11111;
            transform: translate(100%, 0);
            visibility: hidden;
            transition: all 1s cubic-bezier(.25, .1, 0, 1.01);
            margin-top: 0;

            &.active {
                visibility: visible;
                transform: translate(0, 0);
            }

            .item {
                padding: 20px 15px;
                border-bottom: $border-default-dark;
                position: relative;

                a {
                    margin: 0;
                }

                &:after {
                    content: "";
                    position: absolute;
                    height: 16px;
                    width: 16px;
                    border: 1px solid $primary-colour;
                    border-radius: 2px;
                    right: $padding;
                    top: 20px;
                }

                &:before {
                    position: absolute;
                    content: "\2713";
                    font-size: 13px;
                    right: 18px;
                    top: 19px;
                    color: #fff;
                    opacity: 0;
                    z-index: 1;
                }

                &:hover {
                    &:after {
                        background-color: $primary-colour;
                    }

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        .swatch-attribute-options {
            padding: 10px;
            border: none;
            margin-top: 0;
        }

        .color {
            width: auto;
            line-height: 40px;
            height: 40px;
            min-width: 40px;
            margin-bottom: 10px;
            padding: 0 10px;
            display: inline-block;
            margin-right: 10px;
        }

        .text {
            width: auto;
            line-height: 40px;
            height: 40px;
            min-width: 40px;
            margin-bottom: 10px;
            padding: 0 10px;
            display: inline-block;
            margin-right: 10px;
        }
    }
}

/* ============================================ *
 * Layerd Nav Top -- Mandev will be used in most project 
 * ============================================ */

/* ============================================ *
 * Catalogsearch result
 * ============================================ */